import moment from 'moment'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'

export function formatPhone(phone) {
  if (!phone) {
    return ''
  }

  // Format mobile numbers starting with 04
  if (phone.startsWith('04')) {
    return phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
  }

  // Format mobile numbers with country code +61
  if (phone.startsWith('+614')) {
    const withoutCountryCode = `0${phone.substring(3)}` // Remove '+61'
    return withoutCountryCode.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
  }

  // Format landline numbers with country code +61
  if (phone.startsWith('+61')) {
    const withoutCountryCode = `0${phone.substring(3)}` // Remove '+61'
    return withoutCountryCode.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3')
  }

  // Format landline numbers starting with 02, 03, 07, 08, etc.
  if (/^0[2378]/.test(phone)) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3')
  }

  return phone
}

export const humanize = (s) => {
  if (typeof s !== 'string') return s
  return s
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/\-/g, ' ')
    .replace(/^[a-z]/, function (m) { return m.toUpperCase() })
}

export const capitalize = (items) => {
  return items.map(item => ({
    ...item,
    highlighted: humanize(item.highlighted),
    label: humanize(item.label)
  }))
}

export function parameterize(string) {
  return string.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '')
}

export function truncateString(str, num) {
  if (!str) {
    return ''
  }

  if (str.length > num) {
    return str.slice(0, num) + "..."
  } else {
    return str
  }
}

export function range(start, stop, step) {
  var a = [start], b = start
  if (typeof start == 'bigint') {
    stop = BigInt(stop)
    step = step ? BigInt(step) : 1n
  } else
    step = step || 1
  while (b < stop) {
    a.push(b += step)
  }
  return a
}

// Basic unique id
export const unique = () => {
  return Math.floor(Math.random() * Date.now()).toString()
}

// Capitalise a string
export const capitalise = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// replacement function for the jquery document ready
export function ready(fn) {
  if (document.readyState !== 'loading') {
    fn()
    return
  }
  document.addEventListener('DOMContentLoaded', fn)
}

export const getMeilisearchConfig = (host = 'meilisearch-host', apiKey = 'meilisearch-api-key') => {
  let meilisearchHostDomElement = document.getElementById(host)
  let meilisearchHost = meilisearchHostDomElement.getAttribute('value')
  let meilisearchApiKey = document.getElementById(apiKey).getAttribute('value')
  return {
    meilisearchHost: meilisearchHost,
    meilisearchApiKey: meilisearchApiKey
  }
}

export function time_ago(time) {

  switch (typeof time) {
    case 'number':
      break
    case 'string':
      time = +new Date(time)
      break
    case 'object':
      if (time.constructor === Date) time = time.getTime()
      break
    default:
      time = +new Date()
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1

  if (seconds == 0) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = 'from now'
    list_choice = 2
  }
  var i = 0,
    format
  while (format = time_formats[i++])
    if (seconds < format[0]) {
      if (typeof format[2] == 'string')
        return format[list_choice]
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
    }
  return time
}

export const getInitials = function (string) {
  if (typeof string !== 'string' || !string.trim()) {
    return '' // Return empty string or handle as needed
  }

  var names = string.replace(/\(|\)/g, "").split(' ')
  var initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }

  return initials
}

export function timeAgoInDays(created_at) {
  var m = moment(created_at)
  var today = moment().startOf('day')
  var days = Math.round(moment.duration(today - m).asDays())
  if (days < 0) return 0
  return days
}

export const statusClassNames = {
  "converted": "success",
  "contacted": "warning",
  "new": "info",
  "actioned": "info",
  "appointment_set": "warning",
  "lost": "danger",
  "spam": "danger",
}

export const leadStatusOptions = [
  { label: 'New', value: 'new', colour: statusClassNames['new'] },
  { label: 'Actioned', value: 'actioned', colour: statusClassNames['new'] },
  { label: 'Contacted', value: 'contacted', colour: statusClassNames['contacted'] },
  { label: 'Appointment Set', value: 'appointment_set', colour: statusClassNames['appointment_set'] },
  { label: 'Converted', value: 'converted', colour: statusClassNames['converted'] },
  { label: 'Lost', value: 'lost', colour: statusClassNames['lost'] },
  { label: 'Spam', value: 'spam', colour: statusClassNames['spam'] },
]

export const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute('content')

export const standardHeaders = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector("meta[name=csrf-token]")?.content,
  'Accept': 'application/json'
}

export const COLOURS = {
  primary: "#377dff"
}

export const snakifyObject = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key.replace(/([A-Z])/g, "_$1").toLowerCase()] = obj[key]
    return acc
  }, {})
}

export const camelifyObject = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase() })] = obj[key]
    return acc
  }, {})
}

export const meilisearchClient = () => {
  const { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig(
    'meilisearch-internal-host',
    'meilisearch-internal-key'
  )

  const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey, {
    requestConfig: {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }
  })

  return searchClient
}